<div class="container">
  <lib-form-test-buttons
    *ngIf="!environment.production"
    class="container__section container__section_test"
    (emailChange)="formEmail = { email: $event, email2: $event }"
    [address]="formShippingAddress"
    (addressChange)="formShippingAddress = $event"
    [addressDefault]="addressDefault"
  ></lib-form-test-buttons>
  <!-- <app-form-customer-info
    class="container__section"
    headerTitle="お届け先情報"
    [(data)]="formCustomerInfo"
    (dataChange)="onChange()"
    [touchEventEmitter]="touchEventEmitter"
  ></app-form-customer-info> -->
  <lib-form-email
    class="container__section"
    headerTitle="連絡先"
    headerSubtitle="ショップからの連絡（ご注文内容・発送など）をさせていただきます"
    [(data)]="formEmail"
    (dataChange)="onChange()"
    [touchEventEmitter]="touchEventEmitter"
  ></lib-form-email>
  <lib-form-stripe-address
    class="container__section"
    headerTitle="お届け先情報"
    [(data)]="formShippingAddress"
    (dataChange)="onChange()"
    [touchEventEmitter]="touchEventEmitter"
    countryCodeDefault="JP"
    [allowedCountries]="['JP']"
    [regionNameEnglishDefault]="regionNameEnglishDefault"
    [parseStateFn]="parseStateFn"
  ></lib-form-stripe-address>
  <app-section-message-date-alt
    *ngIf="region && (shippingMethod$ | async) as shippingMethod"
    class="container__section container__section_date"
    [region]="region"
    [shippingMethod]="shippingMethod"
    [isGift]="true"
  ></app-section-message-date-alt>
  <div class="container__section">
    <lib-navigation
      nextText="完了"
      [isBackDisabled]="isBackDisabled"
      [isNextDisabled]="isNextDisabled"
      (backClick)="backClick.emit()"
      (nextClick)="nextClick.emit()"
    ></lib-navigation>
  </div>
</div>
