import { Injectable } from '@angular/core';
import {
  INPUT_NAME_KIDS_NAME,
  INPUT_NAME_GLASSES,
  INPUT_NAME_HAIR_COLOR,
  INPUT_NAME_CLOTH_COLOR,
  INPUT_NAME_HAIR_STYLE,
  INPUT_NAME_SKIN_COLOR,
} from '@shared/common/book/constants';
import type { TBookAlias, TDestinations } from '@shared/jp/book/interfaces';
import type { ICommonBookData } from '@shared/jp/models';
import { LoaderService } from '@studiobuki/web-core/lib/loader';
import {
  BOOK_ALIAS_TRAVEL,
  INPUT_NAME_DESTINATIONS,
  INPUT_NAME_KUN_CHAN,
} from '@shared/jp/book/constants';
import { BookTravelService } from 'src/app/pages/book-page/pages/book-travel-page/book-travel.service';
import { multiselectValueToArray } from 'src/utils';

@Injectable({
  providedIn: 'root',
})
export class BooksService {
  constructor(
    private _bookTravelService: BookTravelService,
    private _loader: LoaderService,
  ) {}

  async createBookByAlias(
    alias: TBookAlias,
    /** IBookInputNameToValueMap */
    bookCreateData: any,
    common?: Partial<ICommonBookData>,
  ) {
    this._loader.show();

    try {
      switch (alias) {
        case BOOK_ALIAS_TRAVEL:
          return await this._bookTravelService.createBook(
            {
              heroName: bookCreateData[INPUT_NAME_KIDS_NAME],
              kunChan: bookCreateData[INPUT_NAME_KUN_CHAN],
              destinations: multiselectValueToArray<
                Record<TDestinations, boolean>
              >(bookCreateData[INPUT_NAME_DESTINATIONS]),
              hairColor: bookCreateData[INPUT_NAME_HAIR_COLOR],
              clothColor: bookCreateData[INPUT_NAME_CLOTH_COLOR],
              hairStyle: bookCreateData[INPUT_NAME_HAIR_STYLE],
              skinColor: bookCreateData[INPUT_NAME_SKIN_COLOR],
              glasses: bookCreateData[INPUT_NAME_GLASSES],
            },
            common,
          );
        default:
          throw new Error(`Unexpected alias "${alias}"`);
      }
    } catch (e: any) {
      alert(`Something went wrong. ${'message' in e ? e.message : e}`);
    }

    this._loader.hide();
  }
}
