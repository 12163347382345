import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import type { IBook } from '@shared/jp/data/books/types';
import { TBookPhoto } from '@shared/common/data/books/photos/types';

@Component({
  selector: 'app-book-item',
  templateUrl: './book-item.component.html',
  styleUrls: ['./book-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookItemComponent {
  @Input() caption!: IBook['caption'];

  @Input() photo!: TBookPhoto;

  @Input() summary!: IBook['summary'];

  @Output() buttonClick = new EventEmitter<void>();

  // constructor() {}

  public onButtonClick(): void {
    this.buttonClick.emit();
  }
}
