/* eslint-disable @typescript-eslint/naming-convention */
import type { TextStyle } from '@studiobuki/web-core/lib/book-common';

export const CherryBombOne_white_20: TextStyle = {
  fill: '#ffffff',
  fontFamily: 'CherryBombOne',
  fontSize: 20,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const ZenMaruGothicBoldKunchan_black_14: TextStyle = {
  fill: '#000000',
  fontFamily: 'ZenMaruGothicBoldKunchan',
  fontSize: 14,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const ZenMaruGothicBoldKunchan_black_16: TextStyle = {
  fill: '#000000',
  fontFamily: 'ZenMaruGothicBoldKunchan',
  fontSize: 16,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const ZenMaruGothicBoldKunchan_black_22: TextStyle = {
  fill: '#000000',
  fontFamily: 'ZenMaruGothicBoldKunchan',
  fontSize: 22,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const ZenMaruGothicBoldKunchan_black_message_14: TextStyle = {
  fill: '#000000',
  fontFamily: 'ZenMaruGothicBoldKunchan',
  fontSize: 14,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const ZenMaruGothicBoldKunchan_dc9940_17: TextStyle = {
  fill: '#dc9940',
  fontFamily: 'ZenMaruGothicBoldKunchan',
  fontSize: 17,
  letterSpacing: 1,
  lineHeight: 1.5,
};

export const ZenMaruGothicBoldKunchan_3B4171_17: TextStyle = {
  fill: '#3B4171',
  fontFamily: 'ZenMaruGothicBoldKunchan',
  fontSize: 17,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const ZenMaruGothicBoldKunchan_white_14: TextStyle = {
  fill: '#ffffff',
  fontFamily: 'ZenMaruGothicBoldKunchan',
  fontSize: 14,
  letterSpacing: 1,
  lineHeight: 1.5,
};

export const ZenMaruGothicBoldKunchan_black_61: TextStyle = {
  fill: '#000000',
  fontFamily: 'ZenMaruGothicBoldKunchan',
  fontSize: 61,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const APJapanesefont_black_21: TextStyle = {
  fill: '#000000',
  fontFamily: 'APJapanesefont',
  fontSize: 21,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const APJapanesefont_black_34: TextStyle = {
  fill: '#000000',
  fontFamily: 'APJapanesefont',
  fontSize: 34,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const APJapanesefont_black_44: TextStyle = {
  fill: '#000000',
  fontFamily: 'APJapanesefont',
  fontSize: 44,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const Huifont_black_20: TextStyle = {
  fill: '#000000',
  fontFamily: 'HuiFont',
  fontSize: 20,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const ArmedBananafont_black_20: TextStyle = {
  fill: '#000000',
  fontFamily: 'ArmedBanana',
  fontSize: 20,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const LightNovelPOPv2_4D3B43_20: TextStyle = {
  fill: '#4D3B43',
  fontFamily: 'LightNovelPOPv2',
  fontSize: 20,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const LightNovelPOPv2_4D3B43_27: TextStyle = {
  fill: '#4D3B43',
  fontFamily: 'LightNovelPOPv2',
  fontSize: 27,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const LightNovelPOPv2_138541_17: TextStyle = {
  fill: '#138541',
  fontFamily: 'LightNovelPOPv2',
  fontSize: 17,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const ZenMaruGothicBoldKunchan_138541_15: TextStyle = {
  fill: '#138541',
  fontFamily: 'ZenMaruGothicBoldKunchan',
  fontSize: 15,
  letterSpacing: 1,
  lineHeight: 1.5,
};
