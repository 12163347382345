import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { IBookBanner } from '@shared/common/data/books/banner/types';
import {
  EBookBannerTextColor,
  EBookBannerTextSize,
} from '@shared/common/data/books/banner/types';
import { MediaObserver } from '@angular/flex-layout';
import { BehaviorSubject } from 'rxjs';
import { Logger } from '@shared/common/logger';
import { MediaService } from '@studiobuki/web-core/lib/media';
import { TBookAlias } from '@shared/jp/book/interfaces';
import Subscriber from '@studiobuki/shared/dist/common/subscriber';
import { getNgClass } from '@studiobuki/web-core';

const sub = new Subscriber();
const log = new Logger('BannerComponent');

/**
 * @default textColor EBookBannerTextColor.dark
 */
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() alias!: TBookAlias;

  @Input() imgSrc!: IBookBanner['imgSrc'];

  @Input() imgSrcLtMd?: IBookBanner['imgSrcLtMd'];

  @Input() text!: IBookBanner['text'];

  @Input() textLtMd?: IBookBanner['textLtMd'];

  /** @default EBookBannerTextColor.dark */
  @Input() textColor: IBookBanner['textColor'] = EBookBannerTextColor.dark;

  /** @default EBookBannerTextSize.md */
  @Input() textSize: IBookBanner['textSize'] = EBookBannerTextSize.md;

  private _backgroundImage = new BehaviorSubject('');

  public backgroundImage = this._backgroundImage.asObservable();

  public readonly getNgClass = getNgClass;

  constructor(
    private _media: MediaObserver,
    public media: MediaService,
  ) {}

  ngOnInit(): void {
    sub.push(
      this._media.asObservable().subscribe(() => {
        this._updateBackgroundImage();
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imgSrc'] || changes['imgSrcLtMd']) {
      this._updateBackgroundImage();
    }
  }

  ngOnDestroy(): void {
    sub.unsubscribe();
  }

  private _updateBackgroundImage(): void {
    const src = this._media.isActive('lt-md') ? this.imgSrcLtMd : this.imgSrc;
    this._backgroundImage.next(`url(${src})`);

    // log.info('changed', { src })
  }
}
