import { Injectable } from '@angular/core';
import {
  INPUT_VALUE_STEP_CHARACTER,
  INPUT_VALUE_STEP_MESSAGE,
  INPUT_VALUE_STEP_STRENGTH,
  INPUT_VALUE_STEP_PHOTO,
  INPUT_VALUE_STEP_DESTINATIONS,
  INPUT_VALUE_COVER_TYPE_HARD,
} from '@studiobuki/shared/dist/common/book/constants';
import { Logger } from '@studiobuki/shared/dist/common/logger';
import {
  BOOK_ALIAS_TRAVEL,
  INPUT_VALUE_STRENGTH_BRAVE,
  PHOTO_TRAVEL,
} from '@studiobuki/shared/dist/jp/book/constants';
import type { IBookTravelData } from '@studiobuki/shared/dist/jp/models';
import { BookService } from '@studiobuki/web-core/lib/book-common';
import type { TCreateUserBookOmitKeys } from '@studiobuki/web-core/lib/firebase';
import { FirebaseService } from '@studiobuki/web-core/lib/firebase';
import { LoaderService } from '@studiobuki/web-core/lib/loader';
import type { TBookMode } from '@studiobuki/web-core/lib/routing';
import { ROUTE_PATH_QUERY_PARAM_MODE_USER } from '@studiobuki/web-core/lib/routing';
import { BookPagesTravelService } from './book-pages-travel.service';
import { getKidstitle } from './segments';

export const bookTravelCreateKeys = [
  'heroName',
  'kunChan',
  'destinations',
  'hairColor',
  'clothColor',
  'hairStyle',
  'skinColor',
  'glasses',
] as const;

export type TBookTravelCreateKeys = (typeof bookTravelCreateKeys)[number];

export type TBookTravelCreateData = Pick<
  IBookTravelData,
  TBookTravelCreateKeys
>;

const log = new Logger('BookTravelService');

@Injectable({
  providedIn: 'root',
})
export class BookTravelService extends BookService<
  IBookTravelData,
  TBookTravelCreateData
> {
  constructor(
    protected override _firebaseService: FirebaseService,
    protected override _bookPagesService: BookPagesTravelService,
    private _loaderService: LoaderService,
  ) {
    super(_firebaseService, _bookPagesService);
  }

  /** a method to create initial book data */
  // eslint-disable-next-line class-methods-use-this
  protected override _createBookData<O extends Partial<IBookTravelData>>(
    /** data to create the book from */
    createBookData: TBookTravelCreateData,
    /** book data to owerride the defaults */
    overrideBookData: O,
  ) {
    const { kunChan, heroName } = createBookData;
    const kidstitle = getKidstitle(kunChan);

    const newBook: Omit<IBookTravelData, TCreateUserBookOmitKeys> = {
      ...createBookData,
      alias: BOOK_ALIAS_TRAVEL,
      cover: INPUT_VALUE_COVER_TYPE_HARD,
      steps: {
        [INPUT_VALUE_STEP_CHARACTER]: {
          stepType: INPUT_VALUE_STEP_CHARACTER,
          isDone: true,
        },
        [INPUT_VALUE_STEP_DESTINATIONS]: {
          stepType: INPUT_VALUE_STEP_DESTINATIONS,
          isDone: true,
        },
        [INPUT_VALUE_STEP_MESSAGE]: {
          stepType: INPUT_VALUE_STEP_MESSAGE,
          isDone: false,
        },
        [INPUT_VALUE_STEP_PHOTO]: {
          stepType: INPUT_VALUE_STEP_PHOTO,
          isDone: false,
        },
        [INPUT_VALUE_STEP_STRENGTH]: {
          stepType: INPUT_VALUE_STEP_STRENGTH,
          isDone: false,
        },
      },
      multiFiles: {
        [PHOTO_TRAVEL]: {
          fileUploadStatus: 0,
        },
      },
      messageText:
        `${heroName}${kidstitle}へ\n` +
        `\n` +
        `このせかいは　広いけれど、\n` +
        `${heroName}${kidstitle}なら　だいじょうぶ。\n` +
        `自分を信じて、はばたいて\n` +
        `ちょうせんしてみてね。\n` +
        `きっと楽しいことが　まってるよ！\n`,
      strength: INPUT_VALUE_STRENGTH_BRAVE,
    };

    return { ...newBook, ...overrideBookData };
  }

  override async loadBook(
    bookId: string,
    onBookDataLoad:
      | ((bookData: IBookTravelData) => Promise<void>)
      | ((bookData: IBookTravelData) => void),
    mode: TBookMode = ROUTE_PATH_QUERY_PARAM_MODE_USER,
  ) {
    const bookData = await super.loadBook(bookId, onBookDataLoad, mode);

    this._bookPagesService.newBookInit(bookData);

    const multiFilesEntries = Object.entries(bookData.multiFiles);
    this._loaderService.pushProgressMax(multiFilesEntries.length * 2);

    await Promise.all(
      multiFilesEntries.map(async ([photoName, data]) => {
        if (data.fileUploadStatus === 10) {
          log.info('loading', photoName);
          await this._multiFileLoad(photoName);
          log.info('loaded', photoName);
        }

        this._loaderService.pushProgressValue(2);
      }),
    );

    this.textChangeSubscribe.next();
    this.messageTextChangeSubscribe.next();

    return bookData;
  }
}
