import type { TSectionSteps } from '@studiobuki/web-core/lib/section-steps';

export const stepsData: TSectionSteps = [
  {
    imgSrc: 'assets/images/step1.png',
    text: [
      '主人公となる子の名前を入れて似て',
      'いるキャラクターを作ります。',
      'すると！絵本のできあがりです。',
    ],
  },
  {
    imgSrc: 'assets/images/step2.png',
    text: [
      '試し読みしながらメッセージや写真',
      'などを加えてカスタマイズ。',
      'さらに特別な一冊に。',
    ],
  },
  {
    imgSrc: 'assets/images/step3.png',
    text: ['ご注文後一冊ずつ印刷・製本して', 'お届けします！'],
  },
];
