<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__text">
        <p class="text">子どもたちが新しい冒険へと<br />羽ばたけるように</p>
      </div>
      <div class="container__button">
        <a
          routerLink="/about"
          class="button"
          >絵本ができるまで</a
        >
      </div>
    </div>
  </div>
</div>
