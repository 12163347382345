<div class="container">
  <app-section-header-gap
    class="container__section container__header"
  ></app-section-header-gap>
  <app-section-details
    class="container__section container__content"
    *ngIf="book | async as book"
    [book]="book"
  ></app-section-details>
</div>
