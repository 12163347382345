<div [ngClass]="getNgClass('wrap', alias)">
  <div class="wrap__container">
    <div [ngClass]="getNgClass('container', alias)">
      <div
        class="container__background"
        [ngStyle]="{
          backgroundImage:
            'url(' +
            ((media.isLtMd$ | async) ? imgSrcLtMd || imgSrc : imgSrc) +
            ')'
        }"
      ></div>
      <div
        class="container__content"
        [ngClass]="[
          'container__content_' + textColor,
          'container__content_' + textSize
        ]"
        [innerHTML]="(media.isLtMd$ | async) ? textLtMd || text : text"
      ></div>
    </div>
  </div>
</div>
