<app-product
  *ngIf="!(discountService.activeDiscountCampaign$ | async)"
  [photo]="book.menuPhoto"
  [caption]="book.caption"
  [summary]="book.summary"
  [notice]="book.notice"
  [price]="$any(book.prices[0]?.[1])"
  (buttonClick)="onButtonClick()"
></app-product>
<app-product
  *ngIf="discountService.activeDiscountCampaign$ | async as discountCampaign"
  [photo]="book.menuPhoto"
  [caption]="book.caption"
  [summary]="book.summary"
  [notice]="book.notice"
  [price]="$any(book.prices[0]?.[1])"
  [discount]="getBooksDiscount(discountCampaign, book.alias)"
  (buttonClick)="onButtonClick()"
></app-product>
