import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@studiobuki/web-core/lib/button';
import { PriceModule } from '@studiobuki/web-core/lib/price';
import { ProductComponent } from './product.component';

@NgModule({
  declarations: [ProductComponent],
  imports: [CommonModule, ButtonModule, PriceModule],
  exports: [ProductComponent],
})
export class ProductModule {}
