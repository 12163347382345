import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from '@studiobuki/shared/dist/common/logger';
import type { TBookAlias } from '@studiobuki/shared/dist/jp/book/interfaces';
import type { IBook } from '@studiobuki/shared/dist/jp/data/books/types';
import { BehaviorSubject } from 'rxjs';
import { getBookByAlias } from 'src/app/data/books/utils';

const log = new Logger('PreviewPageComponent');

@Component({
  templateUrl: './preview-page.component.html',
  styleUrls: ['./preview-page.component.scss'],
})
export class PreviewPageComponent implements OnInit {
  private _book = new BehaviorSubject<IBook | undefined>(undefined);

  public book = this._book.asObservable();

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((p) => {
      const { alias } = p;
      const path = this._route.routeConfig?.path;

      this._loadBook(alias || path);
    });
  }

  private _loadBook(alias?: TBookAlias | string): void {
    try {
      const book = getBookByAlias(alias as TBookAlias);

      // FBTrack(FBEvents.viewContent, {
      //   content_ids: [ALIAS_TO_FB_CONTENT_ID_MAP[book.alias]],
      //   content_type: 'product',
      // });

      this._book.next(book);
    } catch (err) {
      log.error(err);
      this._router.navigate(['404']).catch((_err) => log.error(_err));
    }
  }
}
