<div
  class="container"
  [ngClass]="{
    container_inverted: inverted
  }"
>
  <div
    #eyeLeft
    class="container__eye container__eye_left"
  >
    <div
      class="container__eyeball"
      #eyeBallLeft
      [ngStyle]="{
        marginLeft: marginLeft | async,
        marginTop: marginTop | async
      }"
    ></div>
  </div>
  <div
    #eyeRight
    class="container__eye container__eye_right"
  >
    <div
      class="container__eyeball"
      #eyeBallRight
      [ngStyle]="{
        marginLeft: marginLeft | async,
        marginTop: marginTop | async
      }"
    ></div>
  </div>
  <img
    class="container__image"
    src="/assets/images/jal.png"
    alt=""
  />
</div>
