import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { REQUIRED_ERROR } from '@shared/jp/data/books/inputs/validators.constants';
import type { IOrderData } from '@shared/jp/interfaces';
import type { TCouponData } from '@shared/common/interfaces';
import { Logger } from '@shared/common/logger';
import Subscriber from '@shared/common/subscriber';
import { FirebaseService } from 'src/app/services/firebase.service';
import { StripeService } from '@studiobuki/web-core/lib/stripe';
import isEqual from 'lodash/isEqual';
import { BehaviorSubject } from 'rxjs';
import { EButtonColor } from '@studiobuki/web-core/lib/button';

const log = new Logger('SectionCartComponent');

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
})
export class CouponComponent implements OnInit, OnDestroy {
  @Input() orderId!: IOrderData['orderId'];

  @Input() set coupon(coupon: TCouponData | undefined) {
    if (!isEqual(this.coupon, coupon)) {
      this._coupon = coupon;
      this.couponChange.emit(coupon);
    }
  }

  get coupon() {
    return this._coupon;
  }

  private _coupon: TCouponData | undefined = undefined;

  @Output() couponChange: EventEmitter<TCouponData> = new EventEmitter();

  // * couponId FormControl
  public couponIdControl = new UntypedFormControl('', [Validators.required]);

  // * Errors
  public readonly stripeError$ = new BehaviorSubject<string | undefined>(
    undefined,
  );

  public readonly REQUIRED_ERROR = REQUIRED_ERROR;

  public readonly EButtonColor = EButtonColor;

  private _sub = new Subscriber();

  public loading$ = new BehaviorSubject(true);

  constructor(
    private _firebaseService: FirebaseService,
    private _stripeService: StripeService,
  ) {}

  async ngOnInit() {
    const { orderId } = this;

    this._sub.push(
      this._firebaseService.getStripeCoupon$(orderId).subscribe((coupon) => {
        this.coupon = coupon;

        if (coupon) {
          if ('error' in coupon) {
            this.loading$.next(false);
            this.stripeError$.next(coupon.error);
            this.couponIdControl.setErrors([{ forbidden: true }]);

            log.error('coupon error', coupon);
          } else if ('valid' in coupon) {
            this.loading$.next(false);
            this.stripeError$.next(undefined);
            this.couponIdControl.disable();
          }
        }
      }),

      this.couponChange.subscribe(({ id }) => {
        this.couponIdControl.setValue(id);
      }),
    );

    this.loading$.next(false);
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  /**
   * Applies coupon to the order by `couponId`
   */
  async redeemCoupon() {
    this.loading$.next(true);

    await this._stripeService.redeemCoupon(
      this.orderId,
      this.couponIdControl.value,
    );
  }
}
