import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RoutingService } from 'src/app/services/routing.service';
import {
  PURPOSE_ADVENTURE,
  PURPOSE_BIRTHDAY,
  PURPOSE_BIRTH_GIFT,
  PURPOSE_EDUCATIONAL,
} from '@shared/common/data/books/properties/constants';
import { ROUTE_QUERY_PARAM_PURPOSE } from '@studiobuki/web-core/lib/routing';
import Travel from 'src/app/data/books/Travel';
import type { IBook } from '@studiobuki/shared/dist/jp/data/books/types';

@Component({
  selector: 'app-section-books-preview',
  templateUrl: './section-books-preview.component.html',
  styleUrls: ['./section-books-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionBooksPreviewComponent {
  public readonly Travel = Travel;

  constructor(private _routing: RoutingService) {}

  public onBirthdayBooksButtonClick() {
    return this._routing.goToAllBooks({
      [ROUTE_QUERY_PARAM_PURPOSE]: PURPOSE_BIRTHDAY,
    });
  }

  public onEducationalBooksButtonClick() {
    return this._routing.goToAllBooks({
      [ROUTE_QUERY_PARAM_PURPOSE]: PURPOSE_EDUCATIONAL,
    });
  }

  public onAdventureBooksButtonClick() {
    return this._routing.goToAllBooks({
      [ROUTE_QUERY_PARAM_PURPOSE]: PURPOSE_ADVENTURE,
    });
  }

  public onBirthGiftBooksButtonClick() {
    return this._routing.goToAllBooks({
      [ROUTE_QUERY_PARAM_PURPOSE]: PURPOSE_BIRTH_GIFT,
    });
  }

  public onButtonClick(alias: IBook['alias']) {
    return this._routing.goToPreview(alias);
  }
}
