<div class="wrap">
  <app-section-header-gap></app-section-header-gap>
  <div class="wrap__container">
    <div class="container">
      <p class="container__title">お探しのページが見つかりませんでした</p>
      <p>宝物を探しに旅にでませんか</p>
      <lib-button
        class="container__button"
        (buttonClick)="onHomeButtonClick()"
        [fullWidth]="true"
        >HOME</lib-button
      >
    </div>
  </div>
  <!-- center alignment compenstaion -->
  <!-- <app-section-header-gap></app-section-header-gap> -->
</div>
