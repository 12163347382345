import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Logger } from '@shared/common/logger';
import html2canvas from 'html2canvas';
import { AppModule } from './app/app.module';
import { scrollToElement } from './utils';
import kuroshiro from './app/shared/kuroshiro';

export const logImage = (
  base64string: string,
  width: number,
  height: number,
) => {
  const scrollBarWidth = 15;
  const consoleXPaddigs = 65;
  const isHorizontal = window.outerWidth - window.innerWidth < 50;
  const consoleWidth =
    (isHorizontal ? window.outerWidth : window.outerWidth - window.innerWidth) -
    scrollBarWidth -
    consoleXPaddigs;
  // const consoleHeight = height;

  const k = width > consoleWidth ? consoleWidth / width : 1;

  console.log(
    '%c ',
    `padding: ${(height * k) / 2}px ${(width * k) / 2}px; background-size: contain; background-color: #fff; background-position: center; background-repeat: no-repeat; background-image: url(${base64string});`,
  );
};

const logHtml = async (htmlText: string) => {
  const wrap = document.createElement('div');
  wrap.style.width = '0px';
  wrap.style.height = '0px';
  wrap.style.overflow = 'hidden';

  const container = document.createElement('div');
  container.innerHTML = htmlText;
  container.style.display = 'inline-block';
  // container.style.alignItems = 'baseline';
  container.style.whiteSpace = 'nowrap';

  wrap.appendChild(container);

  document.body.appendChild(wrap);

  const canvas = await html2canvas(container, {
    backgroundColor: 'transparent',
  });
  const canvasStr = canvas.toDataURL();

  logImage(canvasStr, canvas.width, canvas.height);
};

// @ts-ignore
window._logKanji = async (str: string) => {
  const _kuroshiro = await kuroshiro;
  let parsed = await _kuroshiro.convert(str, { mode: 'furigana' });

  parsed = parsed.replaceAll(
    '<ruby',
    '<ruby style="display: inline-flex;flex-direction: column-reverse;align-items: center;vertical-align: bottom;"',
  );
  parsed = parsed.replaceAll('<rt', '<rt style="margin-bottom: -0.25rem;"');

  await logHtml(parsed);
};

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

window.addEventListener('load', () => {
  const style = document.createElement('style');

  style.innerHTML = /* css */ `
      @font-face {
        font-family: NotoSansJP;
        src: url("assets/fonts/NotoSansJP/NotoSansJP-Black.otf") format("opentype");
        font-weight: 800;
        font-display: swap;
      }

      @font-face {
        font-family: NotoSansJP;
        src: url("assets/fonts/NotoSansJP/NotoSansJP-Bold.otf") format("opentype");
        font-weight: 700;
        font-display: swap;
      }

      @font-face {
        font-family: NotoSansJP;
        src: url("assets/fonts/NotoSansJP/NotoSansJP-Medium.otf") format("opentype");
        font-weight: 500;
        font-display: swap;
      }

      @font-face {
        font-family: NotoSansJP;
        src: url("assets/fonts/NotoSansJP/NotoSansJP-Regular.ttf") format("opentype");
        font-weight: 400;
        font-display: swap;
      }

      /* @font-face {
        font-family: NotoSansJP;
        src: url("assets/fonts/NotoSansJP/NotoSansJP-Light.otf") format("opentype");
        font-weight: 300;
        font-display: swap;
      } */

      /* @font-face {
        font-family: NotoSansJP;
        src: url("assets/fonts/NotoSansJP/NotoSansJP-Thin.otf") format("opentype");
        font-weight: 200;
        font-display: swap;
      } */
    `;

  document.head.appendChild(style);
});

const clickHandlerLog = new Logger('CLICK HANDLER');
/**
 * as we have updated our base href we need to fix anchor's logic
 */
window.addEventListener('click', (event) => {
  const log = clickHandlerLog;
  const { target } = event;

  if (target instanceof HTMLAnchorElement) {
    const href = target.getAttribute('href');

    if (!href) {
      log.warn('skipped anchor element that missing href', target);
      return;
    }

    if (href.startsWith('#') && href.length > 1) {
      event.preventDefault();

      // log.info('ANCHOR');

      const id = href.slice(1);
      const element = document.getElementById(href.slice(1));

      if (!element) {
        log.error(`element not found by id "${id}" in anchor`, target);
        return;
      }

      scrollToElement(element);
    }
  }
});

// konva loading fix
const ImageSetter = Image.prototype.__lookupSetter__('src');

// eslint-disable-next-line no-restricted-properties
Image.prototype.__defineSetter__(
  'src',
  // eslint-disable-next-line func-names
  function (this: HTMLImageElement, src: string) {
    ImageSetter.bind(this)(src);

    if (src.startsWith('assets/')) {
      this.setAttribute('crossOrigin', 'anonymous');
    }
  },
);
