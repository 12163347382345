<div class="container">
  <div class="container__section">
    <app-book-item
      caption="世界にはばたけ！世界を知る旅絵本"
      [photo]="Travel.previewPhoto"
      [summary]="[
        '世界は広い、楽しいものがたくさんある。でもあなたなら大丈夫！',
        'はばたいて、世界を見て知って、宝物をみつけよう。'
      ]"
      (buttonClick)="onButtonClick(Travel.alias)"
    ></app-book-item>
  </div>
</div>
