import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductionService } from '@studiobuki/web-core/lib/production';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { TRegion, TShippingMethod } from '@shared/jp/shipping/interfaces';
import { PRODUCTION_TIME_ZONE } from '@shared/jp/production/constants';
import { SHIPPING_METHOD_REGULAR } from '@shared/common/shipping/constants';

@Component({
  selector: 'app-section-message-date-alt',
  templateUrl: './section-message-date-alt.component.html',
  styleUrls: ['./section-message-date-alt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionMessageDateAltComponent {
  @Input() isGift: boolean = false;

  @Input() set region(value: TRegion) {
    this.region$.next(value);
  }

  public region$ = new ReplaySubject<TRegion>(1);

  @Input() set shippingMethod(value: TShippingMethod) {
    this.shippingMethod$.next(value);
  }

  public shippingMethod$ = new ReplaySubject<TShippingMethod>(1);

  public readonly expectedArrivalDate$ = this._productionService
    .getExpectedArrivalDate$(
      this.shippingMethod$,
      this.region$.pipe(map((r) => r.nameEnglish)),
    )
    .pipe(
      map(([date]) =>
        formatInTimeZone(date, PRODUCTION_TIME_ZONE, 'yyyy/MM/dd'),
      ),
    );

  // public readonly endOfCycleDate$ =
  //   this._productionService.endOfCycleDate$.pipe(
  //     map((date) => formatInTimeZone(date, PRODUCTION_TIME_ZONE, 'yyyy/MM/dd')),
  //   );

  public readonly SHIPPING_METHOD_REGULAR = SHIPPING_METHOD_REGULAR;

  constructor(private _productionService: ProductionService) {}
}
