<div class="container">
  <div class="container__title">
    <lib-image-title type="gift">ご利用シーン</lib-image-title>
    <div class="description">
      <p>パーソナルライズ絵本はキャラクターやストーリーのカスタマイズにより</p>
      <p>全てのお子様が物語の主人公になれます。</p>
      <br />
      <p>ギフトコードのご用意もございますので、</p>
      <p>大切な方へのプレゼントとして様々なシーンでご利用いただけます。</p>
    </div>
  </div>
  <div class="container__content">
    <div
      class="gift_images"
      *ngFor="let gift of giftArr"
    >
      <img
        [src]="gift.imgPath"
        alt="gift image"
      />
      <p [innerHTML]="gift.title"></p>
    </div>
  </div>
</div>
