import type {
  TBookAlias,
  TBookCover,
  TBookWrapping,
} from '@shared/jp/book/interfaces';
import type { IBookPrice } from '@shared/jp/data/books/prices/types';
import type { IShipping } from '@shared/jp/data/shipping/types';
import type { IDiscountCampaign } from '@shared/jp/discount/interfaces';
import { calculateDiscount } from '@shared/common/discount/utils';
import {
  getBooksDiscount,
  getShippingDiscount,
} from '@shared/jp/discount/utils';
import type { IBookInOrder, IPaymentInformation } from '@shared/jp/interfaces';
import type { TCouponData } from '@shared/common/interfaces';
import { Logger } from '@shared/common/logger';
import type { TBookData } from '@shared/jp/models';
import type { ICalculatorComponentItem } from '@studiobuki/web-core/lib/calculator';
import type StripeBE from 'stripe';
import memoize from 'lodash/memoize';
import {
  BOOK_ALIAS_TOKYO_METRO,
  BOOK_ALIAS_TRAVEL,
} from '@shared/jp/book/constants';
import {
  getKidstitle,
  isBookTokyoMetroData,
  isBookTravelData,
} from '@shared/jp/book/utils';
import type { TShippingMethod } from '@shared/jp/shipping/interfaces';
import {
  KUNCHAN_VALUE_TO_LABEL_MAP,
  TOKYO_METRO_KANJI_VALUE_TO_LABEL_MAP,
  HERO_TOKYO_METRO_VALUE_TO_LABEL_MAP,
} from '@shared/jp/book/maps';
import jpJP from '@shared/common/translations/ja-JP.json';
import type { IProduct, ISpecs } from './types';
import { getBookByAlias } from './app/data/books/utils';
import { wrappingDefault, wrappings } from './static';
import { getSortedDestinations } from './app/pages/book-page/pages/book-travel-page/segments';

type TJPKey = keyof typeof jpJP;

export const multiselectValueToArray = <T extends Record<string, boolean>>(
  data: T,
) =>
  Object.entries(data)
    .filter(([_, value]) => value)
    .map(([key, _]) => key);

export const arrayToMultiselectValue = <A extends string>(
  value: A[],
  values: A[],
) =>
  values.reduce(
    (acc, v) => {
      acc[v] = value.indexOf(v) !== -1;

      return acc;
    },
    {} as Record<A, boolean>,
  );

export const scrollToElement = (
  element: Element,
  container = document.documentElement,
  _yGap: number | null = null,
): void => {
  const { x, y } = element.getBoundingClientRect();
  let yGap = _yGap || 0;

  if (_yGap === null && container === document.documentElement) {
    const styles = getComputedStyle(container);
    const topbarHeight = parseInt(
      styles.getPropertyValue('--buki-topbar-height'),
      10,
    );
    const headerHeight = parseInt(
      styles.getPropertyValue('--buki-header-height'),
      10,
    );

    yGap = topbarHeight + headerHeight;
  }

  container.scrollBy({
    top: y - yGap,
    left: x,
    behavior: 'smooth',
  });
};

const scrollToSelectorLog = new Logger('scrollToSelector');
export const scrollToSelector = (
  selector: string,
  container = document.documentElement,
  _yGap = null,
): void => {
  const log = scrollToSelectorLog;
  const element = document.querySelector(selector);

  if (!element) {
    log.error('element not found by selector', selector);
    return;
  }

  scrollToElement(element, container, _yGap);
};

export const checkStripeCoupon = (coupon: any): coupon is StripeBE.Coupon =>
  coupon && ('percent_off' in coupon || 'amount_off' in coupon);

export const getCouponDiscount = ({
  percent_off,
  amount_off,
}: StripeBE.Coupon): string => {
  if (percent_off) {
    return `${percent_off}%`;
  }
  if (amount_off) {
    return `${amount_off}`;
  }

  return '0';
};

export const getCalculatorItems = (
  products: IProduct[],
  discountCampaign?: IDiscountCampaign,
  couponData?: TCouponData,
  shipping?: IShipping,
): ICalculatorComponentItem[] => {
  const calculatorItems: ICalculatorComponentItem[] = [];

  calculatorItems.push(
    ...products.map((p) => {
      let price = p.cover.price.value + p.wrapping.price.value;

      const discount =
        discountCampaign && getBooksDiscount(discountCampaign, p.alias);

      if (discount) {
        price = calculateDiscount(price, discount);
      }

      if (checkStripeCoupon(couponData)) {
        price = calculateDiscount(price, getCouponDiscount(couponData));
      }

      return {
        name: '商品',
        price,
        currency: p.cover.price.currency,
      };
    }),
  );

  if (shipping) {
    const shippingMethod = shipping.id;
    let shippingPrice = shipping.price;

    const shippingDiscount =
      discountCampaign && getShippingDiscount(discountCampaign, shippingMethod);

    if (shippingDiscount) {
      shippingPrice = calculateDiscount(shippingPrice, shippingDiscount);
    }

    calculatorItems.push({
      name: '送料',
      price: shippingPrice,
      currency: shipping.currency,
    });
  }

  return calculatorItems;
};

export const createBookInOrderObject = (
  alias: TBookAlias,
  bookId: string,
  wrapping: TBookWrapping,
  cover: TBookCover,
): IBookInOrder => ({
  alias,
  bookId,
  status: 0,
  wrapping,
  cover,
});

export const FEToBEProduct = ({
  alias,
  bookId,
  wrapping,
  cover,
}: IProduct): IBookInOrder =>
  createBookInOrderObject(alias, bookId, wrapping.id, cover.id);

export const getProductPrice = (p: IProduct): IBookPrice => ({
  value: p.cover.price.value + p.wrapping.price.value,
  currency: p.cover.price.currency,
});

export const getCovers = memoize((alias: TBookAlias) => {
  const book = getBookByAlias(alias);

  return book.covers;
});

export const getCover = (alias: TBookAlias, cover: TBookCover) => {
  const covers = getCovers(alias);

  return covers[cover];
};

const getBookDataSpecsLog = new Logger('getBookDataSpecs');

export const getBookDataSpecs = (
  bookData: TBookData,
  exceptFields: string[] = [],
): ISpecs => {
  const log = getBookDataSpecsLog;

  const { heroName, messageText } = bookData;

  const specs: ISpecs = {
    /** hero's name */
    名前: heroName,
  };

  if (isBookTokyoMetroData(bookData)) {
    const { kunChan, trainLine, character, glasses } = bookData;

    Object.assign(specs, {
      /** kunChan */
      'くん・ちゃん付け': `${KUNCHAN_VALUE_TO_LABEL_MAP[kunChan]}`,
      /** hero */
      性別: `${HERO_TOKYO_METRO_VALUE_TO_LABEL_MAP[character]}`,
      /** glasses */
      メガネ: glasses ? 'あり' : 'なし',
      /** trainline */
      運転する路線: `${TOKYO_METRO_KANJI_VALUE_TO_LABEL_MAP[trainLine]}`,
      /** photo */
      写真: 'あり',
      /** dedication */
      'メッセージ（左ページ）': messageText,
    });
  } else if (isBookTravelData(bookData)) {
    const {
      clothColor,
      destinations,
      glasses,
      hairStyle,
      hairColor,
      kunChan,
      skinColor,
      strength,
    } = bookData;

    const [dest1, dest2] = getSortedDestinations(destinations);

    Object.assign(specs, {
      'くん・ちゃん付け': `${KUNCHAN_VALUE_TO_LABEL_MAP[kunChan]}`,
      肌色: jpJP[`travel.skin.${skinColor}` as TJPKey],
      髪型: jpJP[
        `travel.hair-color-and-style.${hairColor}.${hairStyle}` as TJPKey
      ],
      服の色: jpJP[`travel.clothes-color.${clothColor}` as TJPKey],
      メガネ: glasses ? 'あり' : 'なし',
      飛行機の行先:
        jpJP[`travel.countries.${dest1}` as TJPKey] +
        '・' +
        jpJP[`travel.countries.${dest2}` as TJPKey],
      子どもの強み: jpJP[`travel.strength.${strength}` as TJPKey],
      写真: 'あり',
      メッセージ: messageText,
    });
  } else {
    log.error(`didn't recognize`, { bookData });
  }

  // Object.assign(specs, {
  //   /** cover */
  //   表紙の種類: cover ? `「${jpJP[cover]}」` : '',
  // });

  // removing excepted fields
  exceptFields.forEach((field) => delete specs[field]);

  // removing empty fields
  Object.keys(specs).forEach((key) => specs[key] || delete specs[key]);

  return specs;
};

const getProductNameLog = new Logger('getProductName');
export const getProductName = (
  alias: TBookAlias,
  heroName: string,

  // gender?: TGender | TGenderGrandpaGrandma,
  // childrenNames?: string[],
) => {
  const log = getProductNameLog;

  switch (alias) {
    case BOOK_ALIAS_TOKYO_METRO:
      return `うんてんしは${heroName}`;
    case BOOK_ALIAS_TRAVEL:
      return `はばたけ！${heroName} せかいで見つけた たからもの`;
    default:
      log.error(`alias "${alias}" not handled yet`);
      return '';
  }
};

type TGetProductRequiredFields = 'alias' | 'bookId' | 'hero' | 'specs';

type TGetProductExcludedFields = 'covers' | 'wrappings' | 'name';

type TGetProductFields = Required<Pick<IProduct, TGetProductRequiredFields>> &
  PartialUndefined<
    Omit<IProduct, TGetProductRequiredFields | TGetProductExcludedFields>
  >;

export const getProduct = ({
  alias,
  bookId,
  cover,
  hero,
  children,
  gender,
  image,
  wrapping,
  specs,
}: TGetProductFields): IProduct => {
  const book = getBookByAlias(alias);

  const p: IProduct = {
    alias,
    bookId,
    name: getProductName(alias, hero),
    covers: Object.values(book.covers),
    cover: cover || book.covers[book.cover],
    hero,
    image: image || book.menuPhoto,
    wrappings,
    wrapping: wrapping || wrappingDefault,
    specs,
  };

  if (children) {
    p.children = children;
  }

  if (gender) {
    p.gender = gender;
  }

  return p;
};

export const getProductByBookData = (bookData: TBookData) =>
  getProduct({
    alias: bookData.alias,
    bookId: bookData.bookId,
    hero:
      bookData.heroName +
      ('kunChan' in bookData ? getKidstitle(bookData.kunChan) : ''),
    gender: 'gender' in bookData ? bookData.gender : undefined,
    children:
      'grandchildren' in bookData
        ? bookData.grandchildren.data.map(({ childrenName }) => childrenName)
        : undefined,
    wrapping: ((wrapping: TBookWrapping | undefined) =>
      wrapping && wrappings.find((w) => w.id === wrapping))(bookData.wrapping),
    cover: getCover(bookData.alias, bookData.cover),
    specs: getBookDataSpecs(bookData),
  });

export const getPaymentInformation = (
  books: {
    alias: TBookAlias;
    cover: TBookCover;
  }[],
  shippingMethod: TShippingMethod,
  shippingPrice: number,
  type: IPaymentInformation['type'],
  couponData?: TCouponData,
  discountCampaign?: IDiscountCampaign,
) => {
  const shippingDiscount =
    discountCampaign && getShippingDiscount(discountCampaign, shippingMethod);

  // eslint-disable-next-line no-param-reassign
  shippingPrice = shippingDiscount
    ? calculateDiscount(shippingPrice, shippingDiscount)
    : shippingPrice;

  /** raw books price */
  const productSubtotal: IPaymentInformation['productSubtotal'] = books.reduce(
    (price, { alias, cover }) => {
      const bookPrice = getCover(alias, cover).price.value;

      // calculate discount
      let bookPriceWithDiscount = bookPrice;

      const discount =
        discountCampaign && getBooksDiscount(discountCampaign, alias);

      if (discount) {
        bookPriceWithDiscount = calculateDiscount(
          bookPriceWithDiscount,
          discount,
        );
      }

      // eslint-disable-next-line no-param-reassign
      price += bookPriceWithDiscount;

      return price;
    },
    0,
  );

  /** final books price */
  let productTotal = productSubtotal;
  let couponCode: IPaymentInformation['couponCode'];

  // apply stripe coupon
  if (checkStripeCoupon(couponData)) {
    productTotal = calculateDiscount(
      productTotal,
      getCouponDiscount(couponData),
    );
    couponCode = couponData.id;
  }

  const res: IPaymentInformation = {
    discountAmount: productSubtotal - productTotal,
    productSubtotal,
    productTotal,
    shippingPrice,
    orderTotal: productTotal + shippingPrice,
    type,
    paymentMethod: 'Card',
    paymentIntent: 'undefined',
  };

  if (couponCode) {
    res.couponCode = couponCode;
  }

  return res;
};
