import Kuroshiro from 'kuroshiro';
import KuroshiroAnalyzerKuromoji from 'kuroshiro-analyzer-kuromoji';

const kuroshiro = new Promise<Kuroshiro>((resolve, reject) => {
  const _kuroshiro = new Kuroshiro();

  _kuroshiro
    .init(new KuroshiroAnalyzerKuromoji({ dictPath: 'assets/kuromoji/dict' }))
    .then(() => {
      resolve(_kuroshiro);
    }, reject);
});

export default kuroshiro;
