import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@studiobuki/web-core/lib/button';
import { SectionBooksPreviewComponent } from './section-books-preview.component';
import { BookItemComponent } from './components/book-item/book-item.component';

@NgModule({
  declarations: [SectionBooksPreviewComponent, BookItemComponent],
  imports: [CommonModule, ButtonModule],
  exports: [SectionBooksPreviewComponent],
})
export class SectionBooksPreviewModule {}
