import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { PreviewPageComponent } from './preview-page.component';
import { SectionDetailsModule } from './components/section-details/section-details.module';

@NgModule({
  declarations: [PreviewPageComponent],
  imports: [CommonModule, SectionHeaderGapModule, SectionDetailsModule],
})
export class PreviewPageModule {}
