import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormTestButtonsModule } from '@studiobuki/web-core/lib/form-test-buttons';
import { FormEmailModule } from '@studiobuki/web-core/lib/form-email';
import { FormStripeAddressModule } from '@studiobuki/web-core/lib/form-stripe-address';
import { NavigationModule } from '@studiobuki/web-core/lib/navigation';
import { SectionMessageDateAltModule } from 'src/app/shared/section-message-date-alt/section-message-date-alt.module';
import { MainComponent } from './main.component';

@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    FormTestButtonsModule,
    FormEmailModule,
    FormStripeAddressModule,
    NavigationModule,
    SectionMessageDateAltModule,
  ],
  exports: [MainComponent],
})
export class MainModule {}
