<div class="container">
  <div class="container__caption">
    <h4
      class="caption"
      [innerHTML]="caption"
    ></h4>
  </div>
  <div class="container__photo">
    <img
      [src]="photo"
      alt="#"
      class="photo"
    />
  </div>
  <div class="container__summary">
    <div
      *ngFor="let summaryItem of summary"
      class="summary"
    >
      <p
        class="summary__item"
        [innerHTML]="summaryItem"
      ></p>
    </div>
  </div>
  <div class="container__button">
    <lib-button
      color="accent-alt"
      size="xs"
      (buttonClick)="onButtonClick()"
      [fullWidth]="true"
      ><strong>この絵本をつくる</strong></lib-button
    >
  </div>
</div>
