import type { TBookReviews } from '@shared/common/data/books/reviews/types';
import Travel from './Travel';

const Books = [Travel];

export const Reviews = Books.reduce<TBookReviews>((arr, book) => {
  arr.push(...book.reviews);

  return arr;
}, []);

export default Books;
