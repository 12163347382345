import type { AfterViewInit, OnDestroy } from '@angular/core';
import { ElementRef, ViewChild, Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MediaObserver } from '@angular/flex-layout';
import { HeaderService } from '@studiobuki/web-core/lib/header';
import { filter } from 'rxjs/operators';
import {
  ROUTE_PATH_ABOUT,
  ROUTE_PATH_CART,
  ROUTE_PATH_COLLABORATION,
  ROUTE_PATH_CONTACTS,
  ROUTE_PATH_FAQ,
  ROUTE_PATH_GIFT_BUY,
  ROUTE_PATH_INDEX,
  ROUTE_PATH_ORDERCONTRACT,
  ROUTE_PATH_ORDERCONTRACT_GIFT,
  ROUTE_PATH_POLICY,
  ROUTE_PATH_REVIEWS,
  ROUTE_PATH_TERMS,
} from '@studiobuki/web-core/lib/routing';

import Subscriber from '@shared/common/subscriber';
import { UserService } from '@studiobuki/web-core/lib/user';
import type { TBookAlias } from '@shared/jp/book/interfaces';
import type { TBookData } from '@shared/jp/models';
import { BOOK_ALIAS_TRAVEL } from '@studiobuki/shared/dist/jp/book/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  @ViewChild('wrap') wrapRef!: ElementRef<HTMLDivElement>;

  public isMenuActive = false;

  public readonly environment = environment;

  public readonly ROUTE_PATH_INDEX = ROUTE_PATH_INDEX;

  // public readonly LINK_INSTAGRAM = LINK_INSTAGRAM;

  // public readonly LINK_BLOG = LINK_BLOG;

  public readonly BOOK_ALIAS_TRAVEL = BOOK_ALIAS_TRAVEL;

  public readonly ROUTE_PATH_GIFT_BUY = ROUTE_PATH_GIFT_BUY;

  public readonly ROUTE_PATH_FAQ = ROUTE_PATH_FAQ;

  public readonly ROUTE_PATH_ABOUT = ROUTE_PATH_ABOUT;

  public readonly ROUTE_PATH_CONTACTS = ROUTE_PATH_CONTACTS;

  public readonly ROUTE_PATH_COLLABORATION = ROUTE_PATH_COLLABORATION;

  public readonly ROUTE_PATH_CART = ROUTE_PATH_CART;

  public readonly ROUTE_PATH_REVIEWS = ROUTE_PATH_REVIEWS;

  public readonly ROUTE_PATH_ORDERCONTRACT = ROUTE_PATH_ORDERCONTRACT;

  public readonly ROUTE_PATH_ORDERCONTRACT_GIFT = ROUTE_PATH_ORDERCONTRACT_GIFT;

  public readonly ROUTE_PATH_POLICY = ROUTE_PATH_POLICY;

  public readonly ROUTE_PATH_TERMS = ROUTE_PATH_TERMS;

  private readonly _sub = new Subscriber();

  private _timeoutId?: number;

  private readonly _resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(({ contentRect: { height } }) => {
      this._updateHeaderHeight(height);
    });
  });

  constructor(
    public userService: UserService<TBookAlias, TBookData>,
    private router: Router,
    public media: MediaObserver,
    public headerService: HeaderService,
  ) {
    this._sub.push(
      this.router.events
        .pipe(
          filter(
            (event): event is NavigationStart =>
              event instanceof NavigationStart,
          ),
        )
        .subscribe((event) => {
          this.menuClose();
        }),
    );
  }

  ngAfterViewInit(): void {
    this._resizeObserver.observe(this.wrapRef.nativeElement);
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
    this._resizeObserver.disconnect();
    this.headerService.headerHeight$.next(0);
  }

  public getIconItemText(value: string): string | undefined {
    if (this.media.isActive('gt-md')) {
      return value;
    }

    return undefined;
  }

  public menuShow() {
    this.isMenuActive = true;
  }

  public menuClose() {
    this.isMenuActive = false;
  }

  public menuToggle() {
    if (this.isMenuActive) this.menuClose();
    else this.menuShow();
  }

  public onMouseenter() {
    // const delay = 300;

    this._clearTimeout();

    // if (!this.isMenuActive) {
    //   this._timeoutId = window.setTimeout(() => {
    //     delete this._timeoutId;
    //     this.menuShow();
    //   }, delay);
    // }
  }

  public onMouseleave() {
    const delay = 500;

    this._clearTimeout();

    if (this.isMenuActive) {
      this._timeoutId = window.setTimeout(() => {
        delete this._timeoutId;
        this.menuClose();
      }, delay);
    }
  }

  public onBackdropClick() {
    this._clearTimeout();
    this.menuClose();
  }

  public _updateHeaderHeight(value: number) {
    if (this.headerService.headerHeight$.value !== value) {
      this.headerService.headerHeight$.next(value);
    }
  }

  private _clearTimeout(): void {
    window.clearTimeout(this._timeoutId);
    delete this._timeoutId;
  }
}
