<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__title">
        <lib-image-title-custom
          src="assets/images/title-giftcode.png"
          text="JALの主人公になれる絵本"
        ></lib-image-title-custom>
      </div>
      <div class="container__content">
        <p class="text">
          お仕立券のように、もっと気軽に贈れるギフトコードでのプレゼントはいかがでしょうか。
          <br /><br />
        </p>
        <p class="text">
          ※ギフトコードはメールで送る英数字のみとなり、印刷されたカード等では届きません。<br />
          ギフトコードを受け取った方が、ギフトコードを利用した際に、絵本がその方宛に届きます。
        </p>
      </div>
    </div>
  </div>
</div>
