<div class="message">
  <!-- <div class="message__img"></div> -->
  <div class="message__txt">
    <ng-container *ngIf="isGift; then giftTpl; else noGiftTpl"></ng-container>
  </div>
</div>
<ng-template #noGiftTpl>
  本日23:30までのお支払い完了で{{ (region$ | async)?.name }}には最短で{{
    expectedArrivalDate$ | async
  }}にお届け<br />
  ※離島・一部山間部を除きます
</ng-template>
<ng-template #giftTpl>
  本日23:30までのお手続き完了で{{ (region$ | async)?.name }}には最短で{{
    expectedArrivalDate$ | async
  }}にお届け<br />
  ※離島・一部山間部を除きます
</ng-template>
