import {
  BOOK_ALIAS_TRAVEL,
  BOOK_PRICE_TRAVEL,
  BOOK_PRICE_TRAVEL_HARD_COVER,
  INPUT_NAME_DESTINATIONS,
  INPUT_NAME_KUN_CHAN,
  INPUT_NAME_STRENGTH,
  INPUT_VALUE_HAIR_COLOR_SILVER,
  INPUT_VALUE_HAIR_STYLE_BOB,
  INPUT_VALUE_HAIR_STYLE_LONG,
  INPUT_VALUE_HAIR_STYLE_LONGBANG,
  INPUT_VALUE_HAIR_STYLE_LONGCURL,
  INPUT_VALUE_HAIR_STYLE_LONG_WAVE,
  INPUT_VALUE_HAIR_STYLE_MEDIUM,
  INPUT_VALUE_HAIR_STYLE_SCARF,
  INPUT_VALUE_HAIR_STYLE_SHAVED,
  INPUT_VALUE_HAIR_STYLE_SHORT,
  INPUT_VALUE_HAIR_STYLE_SHORTBOB,
  INPUT_VALUE_HAIR_STYLE_SHORTCURL,
  INPUT_VALUE_HAIR_STYLE_SHORT_WAVE,
  INPUT_VALUE_HAIR_STYLE_VERYLONG,
  INPUT_VALUE_HAIR_STYLE_VERYSHORT,
  INPUT_VALUE_KUN_CHAN_NASHI,
  INPUT_VALUE_SKIN_COLOR_SILVER,
} from '@shared/jp/book/constants';
import {
  INPUT_NAME_CLOTH_COLOR,
  INPUT_NAME_GLASSES,
  INPUT_NAME_HAIR_COLOR,
  INPUT_NAME_HAIR_STYLE,
  INPUT_NAME_KIDS_NAME,
  INPUT_NAME_SKIN_COLOR,
  INPUT_VALUE_CLOTH_COLOR_GREEN,
  INPUT_VALUE_CLOTH_COLOR_RED,
  INPUT_VALUE_COVER_TYPE_HARD,
  INPUT_VALUE_COVER_TYPE_SOFT,
  INPUT_VALUE_SKIN_COLOR_BLACK,
  INPUT_VALUE_SKIN_COLOR_BROWN,
  INPUT_VALUE_SKIN_COLOR_NUTMEG,
  INPUT_VALUE_SKIN_COLOR_WHITE,
} from '@shared/common/book/constants';
import {
  clothColorTravelArr,
  destinationsArr,
  hairColorTravelArr,
  hairStyleTravelArr,
  kunChanArr,
  skinColorTravelArr,
  strengthArr,
} from '@shared/jp/book/data';
import { getBookCanvasInputData } from '@studiobuki/shared/dist/common/data/books/inputs/canvas/utils';
import { bookInputsToNameValueMap } from '@studiobuki/shared/dist/common/data/books/inputs/utils';
import { getBookRadioInputData } from '@studiobuki/shared/dist/common/data/books/inputs/radio/utils';
import {
  getBookRadioIconInputData,
  getBookRadioIconInputOptionData,
} from '@studiobuki/shared/dist/common/data/books/inputs/radioIcon/utils';
import {
  getBookRadioColorInputData,
  getBookRadioColorInputOptionData,
} from '@studiobuki/shared/dist/common/data/books/inputs/radioColor/utils';
import { KUNCHAN_VALUE_TO_LABEL_MAP } from '@shared/jp/book/maps';
import { EBookBannerTextColor } from '@shared/common/data/books/banner/types';
import { EBookDescriptionTextItemType } from '@shared/common/data/books/desicription/types';
import {
  getBookSelectInputData,
  getBookSelectInputOptionData,
} from '@shared/common/data/books/inputs/select/utils';
import {
  getBookMultiselectInputData,
  getBookMultiselectInputOptionData,
} from '@shared/common/data/books/inputs/multiselect/utils';

import { getBookTextInputData } from '@shared/common/data/books/inputs/text/utils';
import { getBookNameValidatorList } from '@shared/jp/data/books/inputs/validators';
import { GET_BOOK_NAME_ERROR } from '@shared/jp/data/books/inputs/validators.constants';
import {
  PURPOSE_ADVENTURE,
  PURPOSE_BIRTH_GIFT,
} from '@shared/common/data/books/properties/constants';
import type { IBookReview } from '@shared/common/data/books/reviews/types';
import type { IBook } from '@shared/jp/data/books/types';

import { getBookCheckboxInputData } from '@shared/common/data/books/inputs/checkbox/utils';
import { INPUT_NAME_RESERVED_CANVAS1 } from '@studiobuki/shared/dist/common/data/books/inputs/canvas/constants';
import type { IBookCanvasInputItem } from '@studiobuki/shared/dist/common/data/books/inputs/canvas/types';
import type {
  TClothColorTravel,
  TSkinColorTravel,
  THairColorTravel,
  THairStyleTravel,
} from '@studiobuki/shared/dist/jp/book/interfaces';
import { MAKE_MAP } from '@studiobuki/shared/dist/common/utils';
import { EMedia } from '@studiobuki/shared/dist/common/enums';
import { EBookRadioInputStyle } from '@studiobuki/shared/dist/common/data/books/inputs/radio/enums';
import jpJP from '@shared/common/translations/ja-JP.json';
import { getBookPriceData } from '@shared/jp/data/books/prices/utils';
import { getCoverHard, getCoverSoft } from './_utils';

// export const TravelInputs

export const TravelInputsKidsName = getBookTextInputData({
  label: '子どもの下の名前',
  placeholder: 'ひらがな６文字まで',
  name: INPUT_NAME_KIDS_NAME,
  validator: getBookNameValidatorList(6, 2),
  error: GET_BOOK_NAME_ERROR(6),
} as const);

export const TravelInputsKunChan = getBookSelectInputData({
  label: 'くん・ちゃん付け',
  placeholder: '選んでください',
  options: kunChanArr.map((value) =>
    getBookSelectInputOptionData({
      label: KUNCHAN_VALUE_TO_LABEL_MAP[value],
      value,
    }),
  ),
  value: INPUT_VALUE_KUN_CHAN_NASHI,
  name: INPUT_NAME_KUN_CHAN,
} as const);

export const TravelInputsDestinations = getBookMultiselectInputData({
  label: '飛行機の行き先を2カ所選んでください',
  options: destinationsArr.map((value) =>
    getBookMultiselectInputOptionData({
      imgSrc: `assets/images/destinations/${value}.png`,
      label: jpJP[`travel.countries.${value}`],
      value,
    }),
  ),
  max: 2,
  min: 2,
  name: INPUT_NAME_DESTINATIONS,
  styleColumnsConfig: {
    [EMedia.ltSm]: 1,
    [EMedia.ltLg]: 2,
    [EMedia.gtMd]: 4,
  },
});

const skinColorMap = MAKE_MAP<TSkinColorTravel>()({
  silver: '#FFEDE0',
  white: '#FAD7B9',
  nutmeg: '#F7CFA9',
  brown: '#BD8F6F',
  black: '#A47D63',
});

const TravelInputsSkinColor = getBookRadioColorInputData({
  label: '肌色',
  options: skinColorTravelArr.map((value) =>
    getBookRadioColorInputOptionData({
      color: skinColorMap[value],
      value,
    }),
  ),
  value: INPUT_VALUE_SKIN_COLOR_SILVER,
  name: INPUT_NAME_SKIN_COLOR,
});

const hairColorMap = MAKE_MAP<THairColorTravel>()({
  silver: '#F4EAD2',
  blonde: '#F1CE89',
  red: '#C66030',
  brown: '#794B32',
  black: '#4D423B',
});

const TravelInputsHairColor = getBookRadioColorInputData({
  label: '髪色',
  options: hairColorTravelArr.map((value) =>
    getBookRadioColorInputOptionData({
      color: hairColorMap[value],
      value,
    }),
  ),
  value: INPUT_VALUE_HAIR_COLOR_SILVER,
  name: INPUT_NAME_HAIR_COLOR,
});

const clothColorMap = MAKE_MAP<TClothColorTravel>()({
  green: '#89BEC1',
  red: '#F3705B',
});

const TravelInputsClothColor = getBookRadioColorInputData({
  label: '服の色',
  options: [
    ...clothColorTravelArr.map((value) =>
      getBookRadioColorInputOptionData({
        color: clothColorMap[value],
        value,
      }),
    ),
    {
      color: '',
      value: '',
    },
    {
      color: '',
      value: '',
    },
    {
      color: '',
      value: '',
    },
  ],
  value: INPUT_VALUE_CLOTH_COLOR_GREEN,
  name: INPUT_NAME_CLOTH_COLOR,
});

const hairStyleMap = MAKE_MAP<THairStyleTravel>()({
  [INPUT_VALUE_HAIR_STYLE_SHAVED]: 'シェイブド',
  [INPUT_VALUE_HAIR_STYLE_SCARF]: 'スカーフ',
  [INPUT_VALUE_HAIR_STYLE_VERYSHORT]: 'ベリーシ<wbr>ョート',
  [INPUT_VALUE_HAIR_STYLE_SHORT]: 'ショート',
  [INPUT_VALUE_HAIR_STYLE_SHORTBOB]: 'ショー<wbr>トボブ',
  [INPUT_VALUE_HAIR_STYLE_LONGBANG]: 'ロング<wbr>バング',
  [INPUT_VALUE_HAIR_STYLE_MEDIUM]: 'ミディアム',
  [INPUT_VALUE_HAIR_STYLE_BOB]: 'ボブ',
  [INPUT_VALUE_HAIR_STYLE_LONG]: 'ロング',
  [INPUT_VALUE_HAIR_STYLE_VERYLONG]: 'ベリー<wbr>ロング',
  [INPUT_VALUE_HAIR_STYLE_SHORT_WAVE]: 'ショート<wbr>ウェーブ',
  [INPUT_VALUE_HAIR_STYLE_LONG_WAVE]: 'ロングウ<wbr>ェーブ',
  [INPUT_VALUE_HAIR_STYLE_SHORTCURL]: 'ショート<wbr>カール',
  [INPUT_VALUE_HAIR_STYLE_LONGCURL]: 'ロング<wbr>カール',
});

const TravelInputsHairStyle = getBookRadioIconInputData({
  label: '髪型',
  options: hairStyleTravelArr.map((value) =>
    getBookRadioIconInputOptionData({
      // label: hairStyleMap[value],
      imgSrc: `assets/images/hair-style/travel/${value}.png`,
      value,
    }),
  ),
  value: INPUT_VALUE_HAIR_STYLE_SHAVED,
  name: INPUT_NAME_HAIR_STYLE,
  // styleColumnsConfig: {
  //   'gt-md': 3,
  //   'gt-sm': 2,
  //   'lt-md': 1,
  //   // 'lt-sm': 1,
  // },
});

export const TravelInputsGlasses = getBookCheckboxInputData({
  label: 'メガネをかける',
  name: INPUT_NAME_GLASSES,
  value: false,
} as const);

const TravelCanvasInputs = [
  TravelInputsSkinColor,
  TravelInputsHairColor,
  TravelInputsClothColor,
  TravelInputsHairStyle,
  TravelInputsGlasses,
];

// TODO: separate skin and cloth to different images
const TravelItemsSkinAndCloth: IBookCanvasInputItem[] = [
  {
    imgSrc: 'assets/images/t-canvas/M/M01.png',
    showWhen: [
      {
        inputToCheck: TravelInputsClothColor,
        condition: (v: TClothColorTravel) =>
          v === INPUT_VALUE_CLOTH_COLOR_GREEN,
      },
      {
        inputToCheck: TravelInputsSkinColor,
        condition: (v: TSkinColorTravel) => v === INPUT_VALUE_SKIN_COLOR_SILVER,
      },
    ],
  },
  {
    imgSrc: 'assets/images/t-canvas/M/M02.png',
    showWhen: [
      {
        inputToCheck: TravelInputsClothColor,
        condition: (v: TClothColorTravel) =>
          v === INPUT_VALUE_CLOTH_COLOR_GREEN,
      },
      {
        inputToCheck: TravelInputsSkinColor,
        condition: (v: TSkinColorTravel) => v === INPUT_VALUE_SKIN_COLOR_WHITE,
      },
    ],
  },
  {
    imgSrc: 'assets/images/t-canvas/M/M03.png',
    showWhen: [
      {
        inputToCheck: TravelInputsClothColor,
        condition: (v: TClothColorTravel) =>
          v === INPUT_VALUE_CLOTH_COLOR_GREEN,
      },
      {
        inputToCheck: TravelInputsSkinColor,
        condition: (v: TSkinColorTravel) => v === INPUT_VALUE_SKIN_COLOR_NUTMEG,
      },
    ],
  },
  {
    imgSrc: 'assets/images/t-canvas/M/M04.png',
    showWhen: [
      {
        inputToCheck: TravelInputsClothColor,
        condition: (v: TClothColorTravel) =>
          v === INPUT_VALUE_CLOTH_COLOR_GREEN,
      },
      {
        inputToCheck: TravelInputsSkinColor,
        condition: (v: TSkinColorTravel) => v === INPUT_VALUE_SKIN_COLOR_BROWN,
      },
    ],
  },
  {
    imgSrc: 'assets/images/t-canvas/M/M05.png',
    showWhen: [
      {
        inputToCheck: TravelInputsClothColor,
        condition: (v: TClothColorTravel) =>
          v === INPUT_VALUE_CLOTH_COLOR_GREEN,
      },
      {
        inputToCheck: TravelInputsSkinColor,
        condition: (v: TSkinColorTravel) => v === INPUT_VALUE_SKIN_COLOR_BLACK,
      },
    ],
  },
  {
    imgSrc: 'assets/images/t-canvas/M/M06.png',
    showWhen: [
      {
        inputToCheck: TravelInputsClothColor,
        condition: (v: TClothColorTravel) => v === INPUT_VALUE_CLOTH_COLOR_RED,
      },
      {
        inputToCheck: TravelInputsSkinColor,
        condition: (v: TSkinColorTravel) => v === INPUT_VALUE_SKIN_COLOR_SILVER,
      },
    ],
  },
  {
    imgSrc: 'assets/images/t-canvas/M/M07.png',
    showWhen: [
      {
        inputToCheck: TravelInputsClothColor,
        condition: (v: TClothColorTravel) => v === INPUT_VALUE_CLOTH_COLOR_RED,
      },
      {
        inputToCheck: TravelInputsSkinColor,
        condition: (v: TSkinColorTravel) => v === INPUT_VALUE_SKIN_COLOR_WHITE,
      },
    ],
  },
  {
    imgSrc: 'assets/images/t-canvas/M/M08.png',
    showWhen: [
      {
        inputToCheck: TravelInputsClothColor,
        condition: (v: TClothColorTravel) => v === INPUT_VALUE_CLOTH_COLOR_RED,
      },
      {
        inputToCheck: TravelInputsSkinColor,
        condition: (v: TSkinColorTravel) => v === INPUT_VALUE_SKIN_COLOR_NUTMEG,
      },
    ],
  },
  {
    imgSrc: 'assets/images/t-canvas/M/M09.png',
    showWhen: [
      {
        inputToCheck: TravelInputsClothColor,
        condition: (v: TClothColorTravel) => v === INPUT_VALUE_CLOTH_COLOR_RED,
      },
      {
        inputToCheck: TravelInputsSkinColor,
        condition: (v: TSkinColorTravel) => v === INPUT_VALUE_SKIN_COLOR_BROWN,
      },
    ],
  },
  {
    imgSrc: 'assets/images/t-canvas/M/M10.png',
    showWhen: [
      {
        inputToCheck: TravelInputsClothColor,
        condition: (v: TClothColorTravel) => v === INPUT_VALUE_CLOTH_COLOR_RED,
      },
      {
        inputToCheck: TravelInputsSkinColor,
        condition: (v: TSkinColorTravel) => v === INPUT_VALUE_SKIN_COLOR_BLACK,
      },
    ],
  },
];

const TravelItemsHair: IBookCanvasInputItem[] = hairStyleTravelArr
  .map((hairStyle) =>
    hairColorTravelArr.map((hairColor) => {
      const item: IBookCanvasInputItem = {
        imgSrc: `assets/images/t-canvas/H/${hairColor}_${hairStyle}.png`,
        showWhen: [
          {
            inputToCheck: TravelInputsHairColor,
            condition: (v: THairColorTravel) => v === hairColor,
          },
          {
            inputToCheck: TravelInputsHairStyle,
            condition: (v: THairStyleTravel) => v === hairStyle,
          },
        ],
      };

      return item;
    }),
  )
  .flat();

const TravelItemsGlasses: IBookCanvasInputItem[] = [
  {
    imgSrc: 'assets/images/t-canvas/G/G01.png',
    showWhen: [
      {
        inputToCheck: TravelInputsGlasses,
        condition: (v: boolean) => v,
      },
    ],
  },
];

export const TravelInputsCanvasHero = getBookCanvasInputData({
  width: 328,
  height: 559,
  name: INPUT_NAME_RESERVED_CANVAS1,
  label: 'お子さまにそっくりの主人公を作りましょう',
  inputs: TravelCanvasInputs,
  items: [
    ...TravelItemsSkinAndCloth,
    ...TravelItemsHair,
    ...TravelItemsGlasses,
  ],
  value: bookInputsToNameValueMap(TravelCanvasInputs),
  cropLtMd: {
    top: 45,
    bottom: 255,
  },
});

export const TravelInputsStrength = getBookRadioInputData({
  label: '主人公に当てはまる強みを一つ選んでください',
  options: strengthArr.map((value) =>
    getBookMultiselectInputOptionData({
      imgSrc: `assets/images/strength/${value}.png`,
      label: jpJP[`travel.strength.${value}`],
      value,
    }),
  ),
  min: 1,
  max: 1,
  name: INPUT_NAME_STRENGTH,
  style: EBookRadioInputStyle.multiselect,
  styleColumnsConfig: {
    // [EMedia.ltSm]: 1,
    [EMedia.ltLg]: 1,
    [EMedia.gtMd]: 3,
  },
});

const prices = {
  [INPUT_VALUE_COVER_TYPE_SOFT]: getBookPriceData(BOOK_PRICE_TRAVEL),
  [INPUT_VALUE_COVER_TYPE_HARD]: getBookPriceData(BOOK_PRICE_TRAVEL_HARD_COVER),
} as const;

const coverSoft = getCoverSoft({
  price: prices[INPUT_VALUE_COVER_TYPE_SOFT],
  carousel: [
    'assets/images/covers/travel/SC01.jpg',
    'assets/images/covers/travel/SC02.jpg',
    'assets/images/covers/travel/SC03.jpg',
  ],
});
const coverHard = getCoverHard({
  price: prices[INPUT_VALUE_COVER_TYPE_HARD],
  carousel: [
    'assets/images/covers/travel/HC01.jpg',
    'assets/images/covers/travel/HC02.jpg',
    'assets/images/covers/travel/HC03.jpg',
  ],
});

const reviewMock: IBookReview = {
  photoImgSrc: 'https://placehold.co/349',
  userPhotoImgSrc: 'https://placehold.co/100',
  userName: '',
  text: [
    '息子のクリスマスプレゼントで購入🎄🎁',
    '息子が主人公で物語がはじまるとっても素敵な絵本💐',
    '絵も可愛くて息子も母もニコニコ︎︎︎︎︎😀出産祝いにもいいなって思った🕊',
  ],
};

const Travel: IBook = {
  caption: 'せかいで見つけた たからもの',
  summary: [
    '世界って、広い！面白い！でも、あなたなら大丈夫。これからどんどんその世界を広げていく子どもに向けた絵本。',
  ],
  alias: BOOK_ALIAS_TRAVEL,
  prices: [
    [INPUT_VALUE_COVER_TYPE_SOFT, prices[INPUT_VALUE_COVER_TYPE_SOFT]],
    [INPUT_VALUE_COVER_TYPE_HARD, prices[INPUT_VALUE_COVER_TYPE_HARD]],
  ],
  photos: [
    'assets/images/carousel/travel/carousel01-grey.jpg',
    'assets/images/carousel/travel/carousel02-grey.gif',
    'assets/images/carousel/travel/carousel03-grey.jpg',
  ],
  menuPhoto: 'assets/images/bk1-sample.png',
  previewPhoto: 'assets/images/bk1-sample.png',
  // TODO: update properties to make filter wokring
  properties: {
    purpose: [PURPOSE_ADVENTURE, PURPOSE_BIRTH_GIFT],
    age: {
      start: 0,
      end: 3,
    },
  },
  notice: {
    caption: '広いせかいに はばたけ！',
    description:
      '世界って、広い！面白い！でも、あなたなら大丈夫。これからどんどんその世界を広げていく子どもに向けた絵本。',
    points: [
      {
        imgSrc: 'assets/images/age.png',
        caption: '4歳～10歳におすすめ',
      },
      {
        imgSrc: 'assets/images/map.png',
        caption: '世界のお話',
      },
    ],
  },
  points: [
    {
      caption: 'どんなカスタマイズができるの？',
      description: 'TODO: Travel.points[0].description',
    },
    {
      caption: '絵本のストーリーは？',
      description: 'TODO: Travel.points[1].description',
    },
    {
      caption: 'ページ数や絵本の大きさは？',
      description: 'TODO: Travel.points[2].description',
    },
  ],
  inputs: [
    TravelInputsKidsName,
    TravelInputsKunChan,
    TravelInputsDestinations,
    TravelInputsCanvasHero,
  ],
  banner: {
    imgSrc: 'assets/images/banner/travel/JAL-world-banner.jpg',
    imgSrcLtMd: 'assets/images/banner/travel/JAL-world-banner-mobile.jpg',
    text: 'とんでいけ！もっと、とおくまで！',
    textLtMd: 'とんでいけ！<br>もっと、とおくまで！',
    textColor: EBookBannerTextColor.dark,
  },
  description: [
    {
      imgSrc: 'assets/images/description/travel/world-images01.gif',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: /* html */ `<b>絵本の物語の主人公になる</b>`,
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: `この旅絵本の主人公は、その子。主人公のイラストも肌色や髪色、髪型などを組み合わせてその子にそっくりなイラストを作りましょう。<br><br>「これ僕・私だ！」と言ってもらえるイラストがきっと見つかります。`,
        },
      ],
    },
    {
      imgSrc: 'assets/images/description/travel/world-image02-grey.jpg',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: /* html */ `<b>この世界にはばたく子に向けたメッセージを</b>`,
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: `絵本の１ページ目にはその子に向けたメッセージを入れることができます。<br><br>「大好きだよ」「いつも見守っているよ」「あなたは宝物だよ」どんなメッセージを書きますか？`,
        },
      ],
    },
    {
      imgSrc: 'assets/images/description/travel/world-image03-grey.jpg',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: /* html */ `<b>写真を入れて更に特別な一冊に</b>`,
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: `絵本の中に登場するパスポート、載っている写真はもちろんその子のもの。<br><br>今のかわいらしい笑顔の写真を絵本に残して、世界に一冊の絵本に仕上げましょう。`,
        },
      ],
    },
  ],
  review: {
    text: [
      '国旗に興味を持ち始めた孫に買いました。これでもっと世界への興味を深めていってほしいです。',
    ],
  },
  reviews: [
    reviewMock,
    reviewMock,
    reviewMock,
    reviewMock,
    reviewMock,
    reviewMock,
  ],
  covers: {
    [INPUT_VALUE_COVER_TYPE_HARD]: coverHard,
    [INPUT_VALUE_COVER_TYPE_SOFT]: coverSoft,
  },
  cover: INPUT_VALUE_COVER_TYPE_SOFT,
};

export default Travel;
