<lib-form-header-checkout title="配送方法">
  <div class="container">
    <div class="container__section">
      <app-toggle-cards
        class="cards"
        [(value)]="shipping"
      >
        <app-toggle-cards-item
          class="cards__item"
          *ngFor="let s of shippings"
          [name]="s.name"
          [description]="s.description"
          [price]="{
            value: s.price,
            currency: s.currency
          }"
          [value]="s"
          [disabled]="checkDisabled(s)"
          [discount]="
            getShippingDiscount(
              discountService.activeDiscountCampaign$ | async,
              s.id
            )
          "
        ></app-toggle-cards-item>
      </app-toggle-cards>
    </div>
    <div class="container__section">
      <app-section-message-date-alt
        *ngIf="shipping"
        [region]="region"
        [shippingMethod]="shipping.id"
      ></app-section-message-date-alt>
    </div>
  </div>
</lib-form-header-checkout>
