import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MediaService } from '@studiobuki/web-core/lib/media';
import { RoutingService } from 'src/app/services/routing.service';
import { BOOK_ALIAS_TRAVEL } from '@studiobuki/shared/commonjs/jp/book/constants';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoComponent {
  constructor(
    private _routing: RoutingService,
    public mediaService: MediaService,
  ) {}

  public onButtonClick() {
    return this._routing.goToPreview(BOOK_ALIAS_TRAVEL);
  }
}
