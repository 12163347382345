<div class="container">
  <div class="container__title">
    <div class="title">クーポン</div>
  </div>
  <div class="container__content">
    <div class="content">
      <div class="content__section content__section_input">
        <lib-input
          type="text"
          name="couponId"
          [formControl]="couponIdControl"
          [error]="(stripeError$ | async) || REQUIRED_ERROR"
        ></lib-input>
      </div>
      <div class="content__section content__section_button">
        <lib-button
          [disabled]="
            (loading$ | async) ||
            couponIdControl.invalid ||
            (coupon && (stripeError$ | async) === undefined) ||
            false
          "
          (buttonClick)="redeemCoupon()"
          [loading]="(loading$ | async) || false"
          [color]="EButtonColor['accent-2']"
          >適用する</lib-button
        >
      </div>
    </div>
  </div>
</div>
