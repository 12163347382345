<div
  style="min-height: 100vh; flex-direction: column"
  [ngStyle]="{
    overflow: overflow,
    marginTop: (headerService.topbarHeight$ | async) + 'px'
  }"
>
  <app-header
    class="app-header"
    *ngIf="LayoutHeader === LAYOUT_HEADER.shown"
  ></app-header>
  <div
    fxFlex
    class="app-content"
  >
    <div
      fxLayout="column"
      fxFlexFill
    >
      <div fxFlex>
        <router-outlet></router-outlet>
      </div>
      <!-- <div
        *ngIf="LayoutFooter === LAYOUT_FOOTER.shown"
        class="footer-text"
      >
        ©️やなせたかし
      </div> -->
      <lib-section-footer
        *ngIf="LayoutFooter === LAYOUT_FOOTER.shown"
        [footerMainMenu]="footerMainMenu"
        [footerSideMenu]="footerSideMenu"
        ><a
          href="https://www.jal.co.jp/jp/ja/"
          target="_blank"
        >
          <img
            class="image-footer"
            src="/assets/images/jal.png"
            alt="#"
        /></a>
        <a
          href="https://japanairlinesventures.com/ja/"
          target="_blank"
        >
          <img
            class="image-footer"
            src="/assets/images/japan_airlines_ventures.png"
            alt="#"
        /></a>
      </lib-section-footer>
    </div>
  </div>
</div>

<lib-loader></lib-loader>
