import { Component } from '@angular/core';
import { Logger } from '@shared/common/logger';
import { DiscountService } from '@studiobuki/web-core/lib/discount';
import { MediaService } from '@studiobuki/web-core/lib/media';
import { faqDataCondensed } from 'src/app/data/faq/data';

import { Router } from '@angular/router';
import { ROUTE_PATH_FAQ } from '@studiobuki/web-core/lib/routing';
import type { TFeaturesData } from '@studiobuki/web-core/lib/section-features';
import Books, { Reviews } from 'src/app/data/books';
import { stepsData } from 'src/app/data/steps/data';

const log = new Logger('HomePageComponent');

@Component({
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
  public readonly books = Books;

  public readonly reviews = Reviews;

  public readonly featuresData: TFeaturesData = [
    {
      imgSrc: 'assets/images/feature1.png',
      text: ['世界に一冊の', '特別な絵本'],
    },
    {
      imgSrc: 'assets/images/feature2.png',
      text: ['送料 ¥420 (メール便)', '¥770～ (宅急便)'],
    },
    {
      imgSrc: 'assets/images/feature3.png',
      text: ['環境に配慮した素材を使用'],
    },
    {
      imgSrc: 'assets/images/feature4.png',
      text: ['お誕生日などの', 'プレゼントに'],
    },
  ];

  public readonly stepsData = stepsData;

  public readonly faqDataCondensed = faqDataCondensed;

  constructor(
    public discountService: DiscountService,
    public mediaService: MediaService,
    private _router: Router,
  ) {}

  public onFaqButtonClick() {
    log.info('onFaqButtonClick');
    return this._router.navigate([ROUTE_PATH_FAQ]);
  }
}
